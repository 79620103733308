import { NextPage } from 'next';
import React from 'react';

import AuthAccount from '~/components/AuthAccount';

import Meta from './meta';
import Page from './Page';

const Index: NextPage = (): React.ReactElement<unknown> => (
  <AuthAccount>
    <Page />

    <Meta />
  </AuthAccount>
);

export default Index;
