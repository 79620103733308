import Box from '@mui/material/Box';

import { styled } from '~/helpers/styled';

// eslint-disable-next-line import/prefer-default-export
export const SchoolSearchContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: theme.spacing(2),
}));
