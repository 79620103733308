import { useRouter } from 'next/router';
import React from 'react';

import { routesPermissionsChecker, findBaseURL } from '~/helpers/permissions/index';
import useActiveUser from '~/hooks/useActiveUser';
import useDetailedPermissions from '~/hooks/useDetailedPermissions';
import useGenericPermissions from '~/hooks/useGenericPermissions';
import useSchoolId from '~/hooks/useSchoolId';
import useUserPermission from '~/hooks/useUserPermission';
import { DetailedPermission } from '~/types';

export default function useURLPermissions(): boolean | null {
  const [hasPermissions, setHasPermissions] = React.useState<boolean | null>(null);
  const activeUser = useActiveUser();
  const getDetailedPermissions = useDetailedPermissions();
  const getGenericPermissions = useGenericPermissions();
  const { permissions: userPermissions } = useUserPermission();
  const router = useRouter();
  const schoolId = useSchoolId();

  const path = router?.asPath;
  const basePath = findBaseURL(path);

  const userDetailedPermissions = getDetailedPermissions.data?.detailedPermissions as unknown as DetailedPermission[];
  const userGenericPermissions = getGenericPermissions.data?.genericPermissions;

  const permissionChecker = React.useMemo(
    () =>
      routesPermissionsChecker({
        activeUser,
        basePath,
        path,
        schoolId,
        userDetailedPermissions,
        userGenericPermissions,
        userPermissions,
      }),
    [basePath, activeUser, userGenericPermissions, userDetailedPermissions, schoolId, path, userPermissions],
  );

  React.useEffect(() => {
    if (!getDetailedPermissions.loading && !getGenericPermissions.loading) {
      setHasPermissions(permissionChecker);
    }
  }, [getDetailedPermissions, getGenericPermissions, permissionChecker, setHasPermissions]);

  return hasPermissions;
}
