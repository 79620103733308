import { gql } from '@apollo/client';
import { NamedGQLResult, wrapRestQuery } from '@encoura/apollo-rest-utils';

import { REST } from '~/constants/rest';
import { FiniteQueryState, toFiniteState } from '~/helpers/apollo/finiteQueryState';
import useToken from '~/hooks/useToken';
import { GenericPermissions } from '~/types';

type QueryType = NamedGQLResult<'genericPermissions', GenericPermissions>;

const useGenericPermissions = (): FiniteQueryState<QueryType> => {
  const token = useToken();
  const queryState = wrapRestQuery<'genericPermissions'>()(
    gql`
      query WithGenericPermissionsQuery {
        genericPermissions {
          applicationManagement
          credentialSubmission
          e4eReportAdmin
          highSchoolAdmin
          mentoring
          research
        }
      }
    `,
    { endpoint: REST.GET.GENERIC_PERMISSIONS, skip: !token },
  );

  return toFiniteState(queryState);
};

export default useGenericPermissions;
