import { REPORT_MODULES } from '~/constants/modules';
import { Role } from '~/constants/roles';
import USER_TYPES from '~/constants/userTypes';
import { DetailedPermission, GenericPermissions, IActiveUser } from '~/types';

function filter<T>(sourceArray: T[], filterOption: (element: T) => boolean): T[] {
  return sourceArray.filter(element => filterOption(element));
}

const bySchoolExternalId =
  (externalId: string) =>
  (permission: DetailedPermission): boolean =>
    (permission?.organization?.externalId as string) === externalId;

const detailedPermissionsFilters = {
  bySchoolExternalId,
};

const isLdapUser = (user: IActiveUser): boolean => user?.type === USER_TYPES.LDAP;

const userHasAccessToModule = (userGenericPermissions: GenericPermissions, moduleName: string): boolean =>
  Object.keys(userGenericPermissions).includes(moduleName);

const userHasAccessToModuleInSchool = (
  userGenericPermissions: GenericPermissions,
  userDetailedPermissions: DetailedPermission[],
  moduleName: string,
  hsId: string,
  userPermissions: string[],
): boolean => {
  if (userPermissions.length > 0) return userPermissions.some(permission => permission === moduleName);
  if (userHasAccessToModule(userGenericPermissions, moduleName)) {
    const schoolPermissions = filter<DetailedPermission>(
      userDetailedPermissions,
      detailedPermissionsFilters.bySchoolExternalId(hsId),
    ).find(el => el);

    const permissions = schoolPermissions?.permissions || { [moduleName]: false };

    const access = permissions[moduleName as keyof typeof permissions];

    return !!access;
  }
  return false;
};

const userHasAccessToSchoolReportInSchool = (
  userDetailedPermissions: DetailedPermission[],
  reportModuleName: string,
  hsId: string,
): boolean => {
  const schoolPermissions = filter<DetailedPermission>(
    userDetailedPermissions,
    detailedPermissionsFilters.bySchoolExternalId(hsId),
  ).find(el => el);
  const permissions = schoolPermissions?.permissions || { [reportModuleName]: false };

  const access = permissions[reportModuleName as keyof typeof permissions];
  return !!access;
};

const userHasAccessToAdministrationInSchool = (
  userGenericPermissions: GenericPermissions,
  userDetailedPermissions: DetailedPermission[],
  user: IActiveUser,
  hsId: string,
  userPermissions: string[],
): boolean => {
  if (hsId === 'Mentor') return false;
  if (isLdapUser(user)) return true;
  if (
    userHasAccessToModuleInSchool(
      userGenericPermissions,
      userDetailedPermissions,
      'highSchoolAdmin',
      hsId,
      userPermissions,
    )
  )
    return true;
  return false;
};

const userHasAccessToApplicationManagementInSchool = (
  userGenericPermissions: GenericPermissions,
  userDetailedPermissions: DetailedPermission[],
  user: IActiveUser,
  hsId: string,
  userPermissions: string[],
): boolean => {
  if (hsId === 'Mentor') return false;
  if (isLdapUser(user)) {
    return true;
  }
  if (
    userHasAccessToModuleInSchool(
      userGenericPermissions,
      userDetailedPermissions,
      'applicationManagement',
      hsId,
      userPermissions,
    )
  )
    return true;

  if (
    userHasAccessToModuleInSchool(
      userGenericPermissions,
      userDetailedPermissions,
      'applicationManagement',
      hsId,
      userPermissions,
    )
  ) {
    return true;
  }
  return false;
};

const userHasAccessToMentoring = (
  user: IActiveUser,
  userDetailedPermissions: DetailedPermission[],
  hsId: string,
): boolean => {
  const userIsLdap = isLdapUser(user);
  const userIsEducator = userDetailedPermissions.length > 0;

  if (hsId === 'Mentor' && !userIsLdap && !userIsEducator) return true;

  return userIsLdap || userIsEducator;
};

const findReportModuleName = (path: string | undefined): [string | undefined, string | undefined] => {
  if (path) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_basePath, reportName] = path.split('/').filter(el => el);
    const reportModuleName = REPORT_MODULES[reportName];
    return [reportName, reportModuleName];
  }
  return [undefined, undefined];
};

const reportRequiresPermission = (reportName: string | undefined): boolean => reportName !== 'NationalReport';

const userHasAccessToReport = (
  path: string,
  activeUser: IActiveUser,
  userDetailedPermissions: DetailedPermission[],
  schoolId: string,
  userPermissions: string[],
): boolean => {
  const [reportName, reportModuleName] = findReportModuleName(path);
  if (reportRequiresPermission(reportName)) {
    if (reportName === 'UsersReport') return isLdapUser(activeUser);
    if (userPermissions.length > 0) return userPermissions.some(permission => permission === reportModuleName);
    return userHasAccessToSchoolReportInSchool(userDetailedPermissions, reportModuleName as string, schoolId);
  }
  return true;
};

const userHasAccessToReportDashboard = (userDetailedPermissions: DetailedPermission[]): boolean => {
  return userDetailedPermissions?.some(permissionItem => permissionItem.organization?.role === Role.SUPERINTENDENT);
};

export const findBaseURL = (path: string): string | undefined => {
  const basePath = path.split('/').find(el => el);
  if (basePath) return basePath.toLowerCase();
  return basePath;
};

export const validateBaseData = (
  activeUser: IActiveUser | null,
  userDetailedPermissions?: DetailedPermission[],
  userGenericPermissions?: GenericPermissions,
  schoolId?: string,
): boolean => {
  if (!schoolId) return false;
  if (!activeUser) return false;
  if (!userGenericPermissions) return false;
  if (!userDetailedPermissions) return false;
  return true;
};

interface IPermissionChecker {
  activeUser: IActiveUser | null;
  basePath: string | undefined;
  path: string | undefined;
  schoolId: string | undefined;
  userDetailedPermissions: DetailedPermission[] | undefined;
  userGenericPermissions: GenericPermissions | undefined;
  userPermissions: string[];
}

export const routesPermissionsChecker = (permissions: IPermissionChecker): boolean => {
  const { activeUser, basePath, userGenericPermissions, userDetailedPermissions, schoolId, path, userPermissions } =
    permissions;
  switch (basePath) {
    case 'administration':
      if (validateBaseData(activeUser, userDetailedPermissions, userGenericPermissions, schoolId)) {
        return userHasAccessToAdministrationInSchool(
          userGenericPermissions as GenericPermissions,
          userDetailedPermissions as DetailedPermission[],
          activeUser as IActiveUser,
          schoolId as string,
          userPermissions,
        );
      }
      return false;
    case 'applicationmanagement':
      if (validateBaseData(activeUser, userDetailedPermissions, userGenericPermissions, schoolId)) {
        return userHasAccessToApplicationManagementInSchool(
          userGenericPermissions as GenericPermissions,
          userDetailedPermissions as DetailedPermission[],
          activeUser as IActiveUser,
          schoolId as string,
          userPermissions,
        );
      }
      return false;
    case 'mystudents':
      if (!schoolId) return false;
      if (!activeUser) return false;
      if (!userDetailedPermissions) return false;
      return userHasAccessToMentoring(activeUser, userDetailedPermissions, schoolId);
    case 'reports':
      if (!activeUser) return false;
      if (isLdapUser(activeUser)) return true;
      if (!userDetailedPermissions) return false;
      return userHasAccessToReport(
        path as string,
        activeUser,
        userDetailedPermissions,
        schoolId as string,
        userPermissions || [],
      );
    case 'reportdashboard':
      if (!activeUser) return false;
      if (isLdapUser(activeUser)) return true;
      if (!schoolId) return false;
      if (!userDetailedPermissions) return false;
      return userHasAccessToReportDashboard(userDetailedPermissions);
    default:
      return true;
  }
};
