import NEXT from '~/constants/next';

const getCurrentUrl = (): string | undefined => {
  if (NEXT.IS_CLIENT) {
    return `${window.location.pathname}${window.location.search}`;
  }

  return undefined;
};

export default getCurrentUrl;
