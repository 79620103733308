import { isString } from 'lodash';

export interface IUrlObject {
  pathname: string;
  query?: string;
}

// Router.push allows a bunch of properties on a UrlObject that significantly
// complicate the implementation of isExternalUrl and we don't use them. So for
// now we just ensure only supported properties are used
const ALLOWED_KEYS = ['pathname', 'query'];

export default function isExternalUrl(url: IUrlObject | string): boolean {
  const targetUrl = isString(url) ? url : url.pathname;
  if (!isString(url) && !Object.entries(url).every(([key]) => ALLOWED_KEYS.includes(key))) {
    throw new Error('Only pathname and query properties are allowed on a URLObject');
  }

  return new URL(targetUrl, document.baseURI).origin !== new URL(document.baseURI).origin;
}
