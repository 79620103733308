import { ParsedUrlQueryInput } from 'querystring';

import { isUndefined, omitBy } from 'lodash';
import { useRouter } from 'next/router';
import { FC, ReactElement, useEffect } from 'react';

import { ROUTES } from '~/constants/routes';
import { asError } from '~/helpers/errors';
import isExternalUrl from '~/helpers/isExternalUrl';
import sentryRoutingError from '~/helpers/SentryErrors/sentryRoutingError';

interface IProps {
  external?: boolean;
  params?: ParsedUrlQueryInput;
  to: string;
}

const Redirect: FC<IProps> = ({ external = false, params, to }: IProps): ReactElement<unknown> => {
  const router = useRouter();

  useEffect((): void => {
    const query = omitBy(params, isUndefined);

    if (external) {
      router
        .push(to, {
          query,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((unkErr: unknown) => {
          const error = asError(unkErr);

          sentryRoutingError({ error, message: `Error while redirecting`, to: router.asPath });
        });
    } else {
      if (isExternalUrl(to)) {
        router
          .push({ pathname: ROUTES.INDEX })
          // eslint-disable-next-line promise/prefer-await-to-then
          .catch((unkErr: unknown) => {
            const error = asError(unkErr);

            sentryRoutingError({ error, message: `Error while redirecting`, to: router.asPath });
          });
        return;
      }
      router
        .push({
          pathname: to,
          query,
        })
        // eslint-disable-next-line promise/prefer-await-to-then
        .catch((unkErr: unknown) => {
          const error = asError(unkErr);

          sentryRoutingError({ error, message: `Error while redirecting`, to: router.asPath });
        });
    }
  }, [external, params, router, to]);

  return <div />;
};

export default Redirect;
